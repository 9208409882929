<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Roles</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Roles</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
                <b-col lg="6" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_CREATE_ROLE')"
                        :to="{name: 'create-role'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Create Role
                    </router-link>
                </b-col>
                <b-col lg="6" class="mb-1rem">
                    <b-form-group
                        label="Search"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                        style="font-weight:bold; font-size:1rem !important;"
                    >
                        <b-input-group size="md">
                            <b-form-input
                                v-model="filter"
                                type="search"
                                id="filterInput"
                                placeholder="Type to Search"
                            ></b-form-input>
                            <b-input-group-append>
                                <b-button
                                    :disabled="!filter"
                                    @click="onFiltered"
                                    class="btn btn-primary"
                                >Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <router-link class="circle" v-b-tooltip.hover title="Edit" v-if="hasPermission('CAN_UPDATE_ROLE')" :to="{name: 'edit-role', params:{role:row.item.name}}">
                        <font-awesome-icon
                            icon="pen"
                        ></font-awesome-icon>
                        </router-link>
                        <span class="circle text-danger ml-1" v-b-tooltip.hover title="Delete" @click="info(row.item, row.index, $event.target)" v-if="hasPermission('CAN_DELETE_ROLE')">
                            <font-awesome-icon
                                icon="trash-alt"
                                v-b-modal.modal-center
                            ></font-awesome-icon>
                        </span>
                    </span>
                </template>
            </b-table>
            <!-- Info modal -->
            <b-modal
                :id="infoModal.id"
                :title="infoModal.title"
                centered
                size="sm"
                @hide="resetInfoModal"
            >
                <p v-html="message" class="float-left"></p>
                <div slot="modal-footer" class="w-100">
                    <b-button class="mt-3 float-right" @click="cancel()">Cancel</b-button>
                    <b-button
                        class="mt-3 float-right"
                        variant="danger"
                        v-on:click="deleteRole(infoModal.content)"
                        style="margin-right:10px;"
                    >Delete</b-button>
                </div>
            </b-modal>
        </b-container>
    </div>
</template>
<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true },
                { key: "actions", label: "Actions" }
            ],
            items: [],
            customFilterItems: [],
            totalRows: 1,
            filter: null,
            deleted: "",
            api_error: "",
            infoModal: {
                id: "modal-sm",
                title: "",
                content: ""
            },
            access: false,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message: '',
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_LIST_ROLES");
        this.getRoles();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        info(item, index, button) {
            this.infoModal.title = `Delete Confirmation`;
            this.message = 'Are you sure you want to Delete <b>'+item.name+'</b>?';
            this.infoModal.content = JSON.stringify(item, null, 2);
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.content = "";
        },
        onFiltered() {
            this.items = this.customFilterItems.filter(item => {
                return item.name
                    .toLowerCase()
                    .includes(this.filter.toLowerCase());
            });
            this.totalRows = this.items.length;
        },
        getRoles: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = {};
            this.axios
                .get(this.$api.get_roles, query)
                .then(response => {
                    const result = response.data;
                    this.items = [];
                    result.data.forEach(role => {
                        this.items.push({ name: role });
                        this.customFilterItems.push({ name: role });
                    });
                    this.totalRows = this.items.length;
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        deleteRole: function(role) {
            this.startProgressBar();
            this.axios
                .post(
                    this.$api.delete_role,
                    JSON.parse(role),
                    this.getAuthHeaders()
                )
                .then(() => {
                    this.$root.$emit("bv::hide::modal", this.infoModal.id);
                    this.deleted = "Role Deleted Successfully";
                    this.getRoles();
                    this.updateProgressBar(true);
                })
                .catch(err => {
                    if (!err.response) {
                        this.api_error = default_error.server_error;
                    } else if (
                        err.response &&
                        err.response.data &&
                        err.response.data.message
                    ) {
                        this.api_error = err.response.data.message;
                    } else {
                        this.api_error = default_error.server_error;
                        console.error(err.response.data);
                    }
                    this.updateProgressBar(false);
                    this.showAlert();
                });
        },
        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        }
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>